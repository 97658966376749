

import { db } from "../../config/firebase";
import { convertBotsIntoFree } from "../../utilities/chatbotMethods";
import { setUserBots } from "./userBotsSlice";

//getting data from firebase
export const getDashboardDataAsync = async (_,thunkAPI) => {
  
return new Promise((resolve, reject) => {
    db
  .collection("user_" + localStorage.getItem("tablealias"))
  .doc(localStorage.getItem("agent_id"))
  .collection("chatbot")
  .doc("dashboard")
  .onSnapshot((doc) => {
    
    if(doc.exists){
      
    console.log(doc.data() , ' maggiaa')
    const dashboardData = doc.data()

    convertBotsIntoFree(dashboardData).then((res) => {
        if(res){

          const canopyBots=[]
            const newDashboardData = dashboardData
            var botDataMissing = {};
            const filteredObjects = Object.keys(newDashboardData).reduce((result, key) => {
              if (dashboardData[key].isDelete !== 1) {
                result[key] = dashboardData[key];
              }
              if(dashboardData[key].bot_template_category === undefined){
                botDataMissing = dashboardData[key]
              }
              if(dashboardData[key].canopyIndex &&dashboardData[key].bot_template_category==36){
                canopyBots.push(key)
              }
              return result;
            }, {});
            localStorage.setItem("canopyBots", JSON.stringify(canopyBots));
            thunkAPI.dispatch(setUserBots(filteredObjects))
            resolve()
          // })
        }
    })

    
    // reject(doc)
  }else{
   
    localStorage.clear()
    window.location.href=window.location.origin+"/login";
    resolve()
  }

  }).catch((err) => {
    
    console.log(err)
    reject(err)
  })
}) 

  
}


export const toggleBotStatusAsync = async(data , thunkAPI) => {
  return new Promise((resolve , reject) => {
    var dashboard_details = {};
    dashboard_details[`${data.botId}.status`] = data.newStatus;
      db.collection("user_" + localStorage.getItem("tablealias"))
      .doc(localStorage.getItem("agent_id"))
      .collection("chatbot")
      .doc("dashboard")
      .update(dashboard_details);
      resolve({botId : data.botId , newStatus : data.newStatus})
  })
}