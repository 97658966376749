import { API_URL, API_URL_V2, COMMON_LOGIN_API_URL, LIVE_CHAT_API_URL, PINECONE_CLIENT_NAME, PINECONE_NAME, COMMON_BILLING_URL } from "../config/params";
import Cookies from 'js-cookie';
import { utilities } from "./utils.services";
import axios from 'axios'
import { db, fb, storageConnection } from "./../config/firebase";



const authFetch = async (url, options = {}) => {
  if (!options.headers) options.headers = {};
  await isTokenExpired()
  options.headers['Authorization'] = `Bearer ${utilities.getCookie('refresh-bot')}`;

  try {
    const response = await fetch(url, options);

    return response;

  } catch (error) { throw error; }
};


export async function getRefreshToken() {
  // debugger
  const url = API_URL_V2+'/refresh-token';
  const headers = {
    'Content-Type': 'application/json'
  };
  const body = JSON.stringify({
    "APPYID": utilities.getCookie("APPYID"),
    "extras": {
      "tablealias":localStorage.getItem("tablealias")
    },
    "productName": "chatbot"
  });

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.token;
  } catch (error) {
    console.error('Error fetching refresh token:', error);
    throw error;
  }
}

export function updateRefreshToken() {
  return new Promise((resolve, reject) => {
    getRefreshToken()
      .then((token) => { 
        
          Cookies.set('refresh-bot', token, { domain: 'appypie.com' }); 
          resolve(true)
        }
      ).catch((err)=>{
        reject(false)
      })
  });
}
export async function isTokenExpired() {
  const token =utilities.getCookie('refresh-bot')
  if(token){
    const payload = JSON.parse(atob(token.split('.')[1]));
    const currentTime = Math.floor(Date.now() / 1000);
    if(payload){
      if(payload.exp < currentTime+300){
        console.log("Token is expired");
        await updateRefreshToken()
        return true
       }
       else{
        console.log("Token is not expired");
        return true;
       }
    }
  else{
    await updateRefreshToken()
    return false
  }

  }
  else{
    await updateRefreshToken()
    return false
  }
  // Check if the token is expired

   
}







//get real m data from the api
export const getUserData = async (loginCookie) => {
  return new Promise((resolve, reject) => {
    var requestOptions = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ file_name: loginCookie }),
    };
    fetch(COMMON_LOGIN_API_URL + "/api/common/getuserdetailnew", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === 200) {
          resolve(result.userdata)
        } else {
          resolve(false)
        }
      })
      .catch(err => {
        resolve(false)
      });
  })
}

// SQL FOR HANDOFF LOG 
export const fetchHandoffLog = () => {
  return new Promise((resolve, reject) => {
    var body = [`tablealias=${localStorage.getItem("tablealias")}&tabletype=handoff_logs&Bot_ID=BOTID&startdate="2021-01-01"&enddate="2021-03-15"&graphtype=11`]
    fetch(API_URL_V2 + "/fetch", {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        "Authorization": "Bearer " + utilities.getCookie("APPYID")
      },
      body: body,
      method: "POST"
    })
      .then(res => res.json())
      .then((result) => {
        resolve(result.res[0])
      }).catch((err) => {
        resolve([])
      })
  })
}


// SQL FOR ANSWER
export const fetchAnswerLog = () => {
  return new Promise((resolve, reject) => {
    var body = [`tablealias=${localStorage.getItem("tablealias")}&tabletype=answerlog&Bot_ID=BOTID&startdate="2021-01-01"&enddate="2021-03-15"&graphtype=12`]
    authFetch(API_URL_V2 + "/fetch", {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        "Authorization": "Bearer " + utilities.getCookie("APPYID")
      },
      body: body,
      method: "POST"
    })
      .then(res => res.json())
      .then(result => resolve(result.res[0])).catch(err => resolve([]))
  })
}



export const slackChannelApi = (data) => {
  return new Promise(async (resolve, reject) => {
    let config = {
      method: 'GET',
      params: { access_token: data }
    };

    const url = new URL(`${API_URL_V2}/getSlackChannels`);
    url.searchParams.append('access_token', data);

    authFetch(url)
    .then(res => {
      if (!res.ok) throw new Error('Failed to encrypt billing data');
      return res.json();
    })
    .then(data => resolve(data.channels))
    .catch(err => reject(err));
  })
}



export const trainQA = (newList = [], delList = [], updList = []) => {
  return new Promise((resolve, reject) => {
    var delIds = delList.concat(updList).map(item => item.answerId);
    if (delIds.length) {

      var isSingle = localStorage.getItem("email").includes("@appypie") || localStorage.getItem("email").includes("@yopmail")
      var raw = JSON.stringify({ qaList: delIds, nameSpace: isSingle ? localStorage.getItem("botId") : "", indexName: isSingle ? PINECONE_NAME : PINECONE_CLIENT_NAME })

      var requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: raw,
        redirect: 'follow'
      };

      authFetch(API_URL + "/canopy/removeQA", requestOptions)
        .then(result => {
          if (result.status == 200) {
            var newIds = newList.concat(updList)

            if (newIds.length) {
              var isSingle = localStorage.getItem("email").includes("@appypie") || localStorage.getItem("email").includes("@yopmail")
              var raw = JSON.stringify({ qaList: newIds, nameSpace: isSingle ? localStorage.getItem("botId") : "", indexName: isSingle ? PINECONE_NAME : PINECONE_CLIENT_NAME })

              var requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: raw,
                redirect: 'follow'
              };

              authFetch(API_URL + "/canopy/addQA", requestOptions)
                .then(result => {
                  if (result.status == 200) {
                    resolve({ status: true })
                  } else {
                    resolve({ status: false })
                  }
                })
                .catch(error => { resolve(false) })
            } else {
              resolve({ status: true })
            }
          } else {
            resolve({ status: false })
          }
        })
        .catch(error => { resolve({ status: false }) })
    } else {

      var newIds = newList

      var isSingle = true

      var raw = JSON.stringify({ qaList: newIds, nameSpace: isSingle ? localStorage.getItem("botId") : "", indexName: isSingle ? PINECONE_NAME : PINECONE_CLIENT_NAME })

      var requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: raw,
        redirect: 'follow'
      };

      fetch(API_URL + "/canopy/addQA", requestOptions)
        .then(result => {
          console.log(result, "API URL is of trainText result")
          if (result.status == 200) {
            resolve({ status: true })
          } else {
            resolve({ status: false })
          }
        })
        .catch(error => { resolve(false) })
    }
  })
}


//DEF - logging the activities of chatbot
export const trackData = (key, activityType, description, activityData) => {
  return new Promise(async (resolve, reject) => {
    resolve(true)
  })
}



export const fetchGoogleSpreadSheets = (botId) => {
  return new Promise((resolve, reject) => {
    authFetch(API_URL_V2 + '/gDrivenew/getSpreadSheet',
      {
        method: 'POST',
        headers: {
          'Accept-Language': 'en-GB,en-US;q=0.9,en;q=0.8',
          'Connection': 'keep-alive',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'botId': botId,
          'tablealias': localStorage.getItem('tablealias'),
          'token': utilities.getCookie('APPYID')
        })
      }).then(sheets => {
        resolve(sheets.map(file => ({
          id: file.id,
          name: file.name
        })))
      }).then(data => resolve(data))
      .catch(error => reject(error));
  });
};



export const getAppData = async () => {

  const userData = await getUserData(utilities.getCookie("APPYID"))
  var body = JSON.stringify({
    "method": "getFoodCourtAddedStatus",
    "emailId": userData.email
  });

  var appOptions = await fetch("https://snappy.appypie.com/webservices/FoodCourt.php", {
    method: "post",
    body: body,
    headers: { "Content-Type": "application/json" },
  })
    .catch(error => { return [] });

  appOptions = await appOptions.json()
  return appOptions.res
}






export const trainTextNew = (text, name, fileId) => {
  return new Promise(async (resolve, reject) => {
    const userData = await getUserData(utilities.getCookie("APPYID"))

    if (!userData) {
      resolve(false)
      return
    }

    var isSingle = userData.email.includes("@appypie") || userData.email.includes("@yopmail")

    // Replace one or more newline characters with a space
    text = text.replace(/\n+/g, ' ');

    // Replace two or more consecutive dots with a single dot
    text = text.replace(/\.{2,}/g, '.');
    var raw = JSON.stringify({ text, nameSpace: localStorage.getItem("botId"), indexName: isSingle ? PINECONE_NAME : PINECONE_CLIENT_NAME, name, fileId })
    console.log(raw, "RAW TEXT")
    var apiUrl = API_URL + "/canopy/ingest"

    var requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + utilities.getCookie("APPYID") },
      body: raw,
      redirect: 'follow'
    };
    // console.log(apiUrl,"API URL is this final")
    fetch(apiUrl, requestOptions)

      .then(result => {
        console.log(result, "API URL is of trainText result")
        if (result.status == 200) {
          return result.json()

        } else {
          return false
        }
        // console.log(result)
      }).then(text => {
        if (text) {
          resolve(text)
        }
        else {
          resolve({ name: name, error: true })
        }
      })
      .catch(error => { resolve(false) })
  })
}


export const trainText = (text, name) => {
  return new Promise((resolve, reject) => {
    // Replace one or more newline characters with a space
    text = text.replace(/\n+/g, ' ');
    // Replace two or more consecutive dots with a single dot
    text = text.replace(/\.{2,}/g, '.');
    var raw = JSON.stringify({ text, nameSpace: localStorage.getItem("botId"), name })
    console.log(raw, "RAW TEXT")

    var apiUrl = API_URL_V2 + "/pineCone/trainText"

    var requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + utilities.getCookie("APPYID") },
      body: raw,
      redirect: 'follow'
    };
    // console.log(apiUrl,"API URL is this final")
    fetch(apiUrl, requestOptions)
      .then(result => {
        console.log(result, "API URL is of trainText result")
        if (result.status == 200) {
          return result.json()
        } else {
          return false
        }
        // console.log(result)
      }).then(text => {
        if (text) {
          resolve(text)
        }
        else {
          resolve({ name: name, error: true })
        }
      })
      .catch(error => { resolve(false) })
  })
}


export const deletePC = (alias, delList) => {
  return new Promise((resolve, reject) => {
    var requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + utilities.getCookie("APPYID") },
      body: JSON.stringify({ "nameSpace": alias, "delList": delList }),
      redirect: 'follow'
    };

    fetch(API_URL_V2 + "/pineCone/delete", requestOptions)
      .then(result => { resolve(true) })
      .catch(error => resolve(false))
  })
}



export async function generateJson(prompt) {
  return new Promise(async (resolve, reject) => {
    const body = {
      userInput: prompt,
      companyId: localStorage.getItem("tablealias"),
    }
    const response = await fetch(API_URL_V2 + "/runAiModel/getBotTemplate", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + utilities.getCookie("APPYID")
      },
      body: JSON.stringify(body),
    });
    const result = await response.json();

    if (!result.success) {
      resolve(result.message);
    } else {
      const message = result.message;
      resolve(message);
    }
  })
}



export const textToBot = async (description) => {
  return new Promise(async (resolve, reject) => {
    if (process.env.NODE_ENV == "production") {

      const userData = await getUserData(utilities.getCookie("APPYID"))

      if (!userData) {
        resolve(false)
        return
      }
      var raw = JSON.stringify({
        "action": "aiPrompt",
        "email": userData.email,
        "prompt": description
      });

      var requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + utilities.getCookie("APPYID") },
        body: raw,
        redirect: 'follow'
      };

      fetch("https://us-central1-chatbot-production-d6ea3.cloudfunctions.net/querylog/insert", requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
    }


    let prompt = description

    await generateJson(prompt).then(answer => {

      console.log("BOT FLOW IS READY", typeof answer, answer)
      let startIndex = answer.indexOf('{');
      let endIndex = answer.lastIndexOf('}');

      if (startIndex !== -1 && endIndex !== -1) {
        answer = answer.substring(startIndex, endIndex + 1);

      } else {
        console.log('Invalid JSON string');
      }
      var raw = typeof answer == typeof "abc" ? JSON.stringify(JSON.parse(answer).botFlow) : JSON.stringify(answer.botFlow);
      var botName = typeof answer == typeof "abc" ? JSON.stringify(JSON.parse(answer).botName) : JSON.stringify(answer.botName)
      localStorage.setItem("BOTNAME", botName.replace(/[^\w\s]/g, ''))

      // localStorage.setItem("BotName",botName)

      console.log("BOT FLOW IS READY", typeof answer, raw, botName)
      var requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + utilities.getCookie("APPYID") },
        body: raw,
        redirect: 'follow'
      };

      fetch(API_URL_V2 + "/makeBotNew", requestOptions)
        .then(response => response.json())
        .then(result => resolve(result))
        .catch(error => resolve(false));
    }).catch(err => resolve(false))
  })
}




export const fetchZHDropdown = (connectId, nodeId, funcName, team) => {
  return new Promise(async (resolve, reject) => {

    var formField = team ? { organizationId: team } : {};

    await authFetch(`${API_URL_V2}/connect_integration_new/trigger-dropdown`, {
      method: "post",
      body: JSON.stringify({
        pc_id: localStorage.getItem("tablealias"),
        botId: localStorage.getItem("botId"),
        connectId,
        nodeId,
        funcName,
        formField,
        cookie: document.cookie,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          var resArr = response.data.filter((item) => item.value !== "")
          resolve(resArr);
        } else resolve([]);
      });
  })
}


export const fetchMSDropdown = (connectId, nodeId, team) => {
  return new Promise(async (resolve, reject) => {
    var funcName = team ? "getMicrosoftChannelList" : "getMicrosoftTeamList";
    var formField = team ? { team: team } : {};

    await authFetch(`${API_URL_V2}/connect_integration_new/trigger-dropdown`, {
      method: "post",
      body: JSON.stringify({
        pc_id: localStorage.getItem("tablealias"),
        botId: localStorage.getItem("botId"),
        connectId,
        nodeId,
        funcName,
        formField,
        cookie: document.cookie,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) resolve(response.data);
        else resolve([]);
      });
  });
};


export const getconnectURL = async (appId, integrationUrl) => {
  return new Promise(async (resolve, reject) => {

    const userData = await getUserData(utilities.getCookie("APPYID"))

    if (!userData) {
      resolve(false);
      return;
    }

    var body = [
      `cookie=${document.cookie}&appId=${appId}&botId=${localStorage.getItem(
        "botId"
      )}&integrationUrl=${integrationUrl}&clientId=${localStorage.getItem(
        "tablealias"
      )}&email=${userData.email}&callback_url=${window.location.origin
      }/verify`,
    ];
    authFetch(
      `${API_URL_V2}/connect_integration_new/integration-url`,
      {
        method: "POST",
        body: body,
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then((res) => res.json())
      .then((response) => resolve(response.url))
      .catch((err) => resolve(false));
  });
};


export const verifyOtp = (code, phone, otp) => {
  return new Promise(async (resolve, reject) => {
    fetch(API_URL + "/verifyOtp", {
      method: "POST",
      headers: { "Content-Type": "application/json", "Authorization": "Bearer " + utilities.getCookie("APPYID") },
      body: JSON.stringify({ code, phone, otp }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("OTPPPP ==== ", data);
        if (data.status == "approved") {
          resolve(true);
        } else {
          resolve(false);
        }
        //this.setState({verifyOtp : true})
      })
      .catch((err) => console.log(err));
  });
};



export const sendOtp = (code, phone) => {
  return new Promise(async (resolve, reject) => {
    fetch(API_URL + "/sendOtp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + utilities.getCookie("APPYID")
      },
      body: JSON.stringify({ code, phone })
    })
      .then((status) => {
        if (status.status == "pending") resolve(true);
        else resolve(false);
      })
      .catch((err) => console.log(err));
  });
};


export const openSubscription = (path = "/billing/dashboard") => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + utilities.getCookie("APPYID")
    },
  };

  fetch(
    API_URL_V2 + "/payment_conversion/billingOverview",
    requestOptions
  )
    .then((data) => {
      data.json().then((response) => {
        if (response.message) {
          const form = document.createElement("form");
          form.setAttribute("name", "billingInfo");
          form.setAttribute("id", "billingInfo");
          form.setAttribute("method", "POST");
          form.setAttribute("target", "_blank");
          form.setAttribute(
            "action",
            COMMON_BILLING_URL + path
          );
          var FN = document.createElement("input");
          FN.setAttribute("type", "hidden");
          FN.setAttribute("name", "request");
          FN.setAttribute("value", response.message);

          form.appendChild(FN);

          document.getElementsByTagName("body")[0].appendChild(form);
          form.submit();
          document.body.removeChild(form);
        }
      });
    })
    .catch((error) => window.open(COMMON_BILLING_URL + `/billing/dashboard`, "_blank"));
};




export const whatsappLiveChatSetup = async (whatsappData) => {
  return new Promise((resolve, reject) => {
    var requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(whatsappData),
      redirect: "follow"
    }
    fetch(LIVE_CHAT_API_URL + "whatsapp/integrate", requestOptions)
      .then(res => res.json())
      .then(result => resolve(result))
  })
}




// create new user Big query DB
export const createBigQueryDB = (tablealias) => {
  //API by SHUBHAM
  var body = [
    `tablealias=${tablealias}&tabletype=feedback_log&Bot_ID=BOTID&startdate="2021-01-01"&enddate="2021-01-01"&graphtype=2`,
  ];
  authFetch(API_URL_V2 + "/fetch", {
    method: "post",
    body: body,
    headers: { "Content-Type": "application/x-www-form-urlencoded", "Authorization": "Bearer " + utilities.getCookie("APPYID") },
  });
};


export const makeMoreAppt = async () => {
  // debugger
  await authFetch(API_URL_V2 + "/appointments/makeSlots", {
    method: "post",
    body: [`tablealias=${localStorage.getItem("tablealias")}`],
    headers: { "Content-Type": "application/x-www-form-urlencoded" }
  });
}



export const registerUserInCommonLogin = async (_id) => {
  let alias = localStorage.getItem("tablealias");
  let agent_id = localStorage.getItem("agent_id");
  var body = [`grant_type=password&username=pedroetb&password=password`];

  var a = await new Promise((resolve, rej) => {
    fetch(COMMON_LOGIN_API_URL + "/oauth/token", {
      method: "post",
      body: body,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic YXBwbGljYXRpb246c2VjcmV0`,
      },
    })
      .then((e) => e.json())
      .then(async (response) => {
        resolve(response.accessToken);
      })
      .catch((e) => {
        resolve("e26dda5b4c78903ed190f840e958484482411714");
      });
  });

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  var urlencoded = new URLSearchParams();
  urlencoded.append("user_id", _id);
  urlencoded.append("table_alias", alias);
  urlencoded.append("owner_id", agent_id);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };
  try {
    let r = await fetch(
      COMMON_LOGIN_API_URL + "/api/common/savechatbot?access_token=" + a,
      requestOptions
    );
    r = await r.text();
    console.log(r, "testdheeraj123");
    return true;
  } catch (error) {
    return false;
  }
};



//verify appyid
export const verifyUser = async (loginCookie) => {
  return new Promise((resolve, reject) => {
    var requestOptions = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        file_name: loginCookie
      }),
    };
    fetch(COMMON_LOGIN_API_URL + "/api/common/company/verifyUser", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result, ' response of getuserdetailnew');
        if (result.status === 200 && result.data) {
          resolve({ status: true, data: result.data })
        } else {
          resolve({ status: false })
        }
      })
      .catch(err => {
        console.log(err, ' error in getuserdetailnew')
        resolve(false)
      });
  })
}


export const insertIntoCommonLogin = (tableAlias, agentId) => {
  return new Promise(async (resolve, reject) => {

    //getting the data from the common login

    const userData = await getUserData(utilities.getCookie("APPYID"))

    if (!userData) {
      resolve(false)
      return
    }

    var jsonData = {
      email: userData.email,
      tablealias: tableAlias,
      agent_id: agentId
    };
    var requestOptions = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Cookie": "appssession=oi2m0i8b08bktm01btri5lqij2"
      },
      body: JSON.stringify(jsonData),
      redirect: 'follow'
    };
    fetch(COMMON_LOGIN_API_URL + "/api/users/insertchatbot", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result);

        if (result.status_msg === 200) {
          resolve(true)
        } else {
          resolve(false)
        }
      })
      .catch(err => console.log(err));
  })
}



export const firebaseAuth = async (client_token, _id, alias) => {
  var TOKEN_URL = API_URL_V2 + "/getAuthToken/?client_token=";

  return new Promise(async (resolve, reject) => {
    let res = await fetch(
      TOKEN_URL + client_token + "&_id=" + _id + "&alias=" + alias, {

    }
    );
    const token = await res.text();
    // localStorage.setItem("fbAuthToken", token);
    console.log(token, "stagingToken");

    fb.auth()
      .signInWithCustomToken(token)
      .then(userCredential => resolve(true))
      .catch(error => reject(true));
  });
};


export const fetchGoogleSheets = (botId, spreadSheetId) => {
  return new Promise((resolve, reject) => {
    authFetch(API_URL_V2 + '/gDrivenew/getSpreadSheetByID', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        'botId': botId,
        'tablealias': localStorage.getItem('tablealias'),
        'spreadsheetID': spreadSheetId,
        'token': utilities.getCookie('APPYID')
      })
    }).then(response => {
      if (!response.ok) throw new Error('Network response was not ok');
      return response.json();
    })
      .then(data => {
        resolve(data.map(file => ({
          id: file.id,
          name: file.disp_val
        })));
        resolve(data);
      })
      .catch(error => reject(error));
  });
}


export const deleteCanopy = (alias, delList) => {
  return new Promise(async (resolve, reject) => {
    const userData = await getUserData(utilities.getCookie("APPYID"))

    if (!userData) {
      resolve(false);
      return;
    }

    var isSingle = userData.email.includes("@appypie") || userData.email.includes("@yopmail")
    var raw = JSON.stringify({ nameSpace: localStorage.getItem("botId"), indexName: isSingle ? PINECONE_NAME : PINECONE_CLIENT_NAME, delList })

    var requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + utilities.getCookie("APPYID") },
      body: raw,
      redirect: 'follow'
    };

    authFetch(API_URL + "/canopy/delete", requestOptions)
      .then(result => { resolve(true) })
      .catch(error => {
        resolve(false)
      })
  })
}



export const getUnstructuredUrlText = (url) => {
  return new Promise((resolve, reject) => {

    var requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + utilities.getCookie("APPYID") },
      body: JSON.stringify({ url }),
      redirect: 'follow'
    };

    authFetch(API_URL + "/pineCone/getUnstText", requestOptions)
      .then(result => {
        if (result.status == 200) return result.json();
        else return false;
      }).then(text => {
        if (text.body) resolve(text.body);
        else resolve(false);
      })
      .catch(error => resolve(false));
  })
}


//creating new bot or overwrite old bot from the api
export const createBot = (botName, botType, steps = {}) => {

  return new Promise(async (resolve, reject) => {
    const userData = await getUserData(utilities.getCookie("APPYID"))

    if (!userData) {
      resolve(false)
      return;
    }

    var body = {
      email: userData.email,
      tablealias: localStorage.getItem("tablealias"),
      agentId: localStorage.getItem("agent_id"),
      botId: localStorage.getItem("botId"),
      botName,
      botType,
      steps,
      isNew: true,
    };

    authFetch(API_URL_V2 + "/makeUser/newBot", {
      method: "post",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + utilities.getCookie("APPYID")
      },
    })
      .then((res) => {
        console.log(res, "STATUS", res.status)
        if (res.status) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
  });
};


export const updateChatHistory = async (answerId, chatId, updatedQuestion, updatedAnswer) => {
  return new Promise((resolve, reject) => {
    var raw = JSON.stringify({
      "chatId": chatId,
      "answerId": answerId,
      "tablealias": localStorage.tablealias,
      "agentId": localStorage.agent_id,
      "botId": localStorage.botId,
      updatedQuestion,
      updatedAnswer
    });

    var requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: raw,
      redirect: 'follow'
    };

    authFetch(API_URL_V2 + "/chatHistoryData-v1/updateQA", requestOptions)
      .then(response => response.text())
      .then(result => resolve(JSON.parse(result)))
      .catch(error => resolve(false));
  })
}


export const getJwtApptData = async (token) => {
  return new Promise((resolve, reject) => {
    var requestOptions = {
      method: 'POST',
      headers: { "Authorization": token },
      body: JSON.stringify({}),
      redirect: 'follow'
    };
    authFetch(API_URL_V2 + "/twilioNew/decryptAppt", requestOptions)
      .then(response => response.json())
      .then(result => resolve(result.message))
      .catch(error => null);
  })
}


export const getJwtData = async (token) => {
  return new Promise((resolve, reject) => {
    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({"token":token}),
      redirect: 'follow'
    };
    authFetch(API_URL_V2 + '/twilioNew/decrypt', requestOptions)
      .then(response => response.json())
      .then(result => resolve(result))
      .catch(error => console.log('error', error));
  })
}


// Might not be in use
export const getTextFromUrl = (url) => {
  return new Promise((resolve, reject) => {
    var requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + utilities.getCookie("APPYID"), cookie: "adasd" },
      body: JSON.stringify({ url }),
      redirect: 'follow'
    };
    authFetch(API_URL_V2 + "/pineCone/getUnstText", requestOptions)
      .then(result => {
        if (result.status == 200) return result.json()
        else return false
      }).then(text => {
        if (text) resolve(text.body)
        else resolve(false)
      })
      .catch(error => resolve(false));
  })
}

export const callEncryptedData = async (data) => {
  return new Promise((resolve, reject) => {
    const encryptDecryptData = async (type, d) => {
      return new Promise(async (resolve, reject) => {
        var apiUrl = API_URL_V2 + "/commonBillingencryption"
        var data = JSON.stringify({
          action: type,
          string: d,
        });

        var config = {
          method: "post",
          url: apiUrl,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        await axios(config)
          .then(async (response) => {
            //   console.log(response.data,'encryptdecrypt');
            if (type == "encrypt") {
              // console.log(response.data.string, "sssencrypt");
              resolve(await meterBillingFunction(response.data.string));
            } else {
              // console.log(response.data, "sssdecrypt");
              resolve(response.data);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    };
    const meterBillingFunction = async (dataStr) => {
      return new Promise(async (resolve, reject) => {
        // console.log(dataStr,"meterbillingscript")
        var data = dataStr;
        var config = {
          method: "post",
          url: COMMON_BILLING_URL + '/api',
          mode: "no-cors",
          headers: {
            "Content-Type": "text/plain",
          },
          data: data,
        };
        await axios(config)
          .then((res) => {
            // console.log(res.data, "apiapiresponse");
            resolve(encryptDecryptData("decrypt", res.data));
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    };


    encryptDecryptData("encrypt", data).then((output) => {
      resolve(output)
    })

  })
}

export const encryptActivityLogData = (data) => {

  return new Promise((resolve, reject) => {

    //end point
    // const ENCRYPTION_URL = API_URL + '/payment_encryption'
    const ENCRYPTION_URL = API_URL_V2 + '/commonBillingencryption'


    const payloadData = {
      action: "encrypt",
      string: data
    }

    axios.post(ENCRYPTION_URL, payloadData).then(res => {
      if (res && res.data) {
        const encryptedData = res.data.string
        resolve({
          message: "Success",
          string: encryptedData
        })
      }
      else {
        reject({
          message: "Failed",
          err: "Something went wrong"
        })
      }
    }).catch(err => {
      reject({
        message: "Failed",
        err: err.message
      })
    })
  })

}


export const removeTrainGptData = (alias, delList) => {
  return new Promise((resolve, reject) => {

    var raw = JSON.stringify({
      "alias": alias,
      "addList": delList
    });

    var requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + utilities.getCookie("APPYID") },
      body: raw,
      redirect: 'follow'
    };

    authFetch(API_URL_V2 + "/gpt/delete", requestOptions)
      .then(response => response.json())
      .then(result => { resolve(result) })
      .catch(error => console.log('error', error))
  })

}

export const trainGptData = (alias, addList) => {
  return new Promise((resolve, reject) => {

    var raw = JSON.stringify({
      "alias": alias,
      "addList": addList
    });

    var requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + utilities.getCookie("APPYID") },
      body: raw,
      redirect: 'follow'
    };

    authFetch(API_URL_V2 + "/gpt/train", requestOptions)
      // fetch("http://localhost:5051/chatbot-production-d6ea3/us-central1/gpt/train", requestOptions)

      .then(response => response.json())
      .then(result => { resolve(result) })
      .catch(error => console.log('error', error))
  })

}

//handler function




export const setupGoogleDriveV2 = async (gdriveCode) => {
  const body = JSON.stringify({
    botId: localStorage.getItem("botId"),
    botName: "Chatbot Inquiries",
    gdriveCode: gdriveCode,
    tablealias: localStorage.getItem("tablealias"),
    redirectUrl: window.location.origin,
  });

  try {
    const response = await authFetch(API_URL_V2 + "/gDrivenew/setupGoogleDriveV2", {
      method: "POST",
      body: body,
      headers: { "Content-Type": "application/json" },
    });

    return await response.json(); // Return the JSON response
  } catch (error) {
    console.error(error, "ERROR");
    throw new Error(`Connection to Google sheet has failed due to missing permission`);
  }
};



export const handleCommonBillingEncryption = async (payment, url = API_URL_V2 + "/commonBillingencryption") => {
  try {
    console.log("============ PAYMENT DATA ============== ", payment);

    const response = await authFetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payment),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const resData = await response.json();

    if (resData && resData.string) {
      const form = document.createElement("form");
      form.setAttribute("id", "paymentForm");
      form.setAttribute("method", "POST");
      form.setAttribute("action", COMMON_BILLING_URL + `/index/pay`);

      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", "request");
      input.setAttribute("value", resData.string);

      form.appendChild(input);
      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    }
  } catch (error) {
    console.error("Error during billing encryption: ", error);
    throw new Error("Failed to process the payment request");
  }
};



export const getAuthData = async (token) => {
  const url = `${API_URL_V2}/connect_integration_new/getauth?t=${token}&companyId=${localStorage.getItem("tablealias")}&botId=${localStorage.getItem("botId")}`;
  
  try {
    const response = await authFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Basic Y2hhdGJvYXRAYXBweXBpZS5jb206ZmRoamtvaTQ3ZGdoanJ0dW9kZ251NzY=",
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch auth data: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching auth data:", error);
    throw error;
  }
};


export const addIntegration = async (data) => {
  const url = `${API_URL_V2}/connect_integration_new/add-connect-new`;

  try {
    const response = await authFetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Failed to add integration: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error adding integration:", error);
    throw error;
  }
};


export const sendDeleteBotEmail = async (email, botName, tableAlias, additionalData) => {
  const url = `${API_URL_V2}/sendEmail/deleteBot`;

  // Prepare the body for the request
  const body = `to=${email.toLowerCase()}&&bot_name=${botName}&&tablealias=${tableAlias}&&data=${additionalData}`;

  try {
    const response = await authFetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body,
    });

    if (!response.ok) {
      throw new Error(`Failed to send email: ${response.status}`);
    }

    console.log("Email sent successfully:", await response.json());
  } catch (error) {
    console.error("Error sending delete bot email:", error);
    throw error;
  }
};


export const sendEmailIt = async (email, wigitScript, message) => {
  const apiUrl = `${API_URL}/sendEmail/mailit`;
  const body = {
    to: email,
    data: wigitScript.wigit,
    tablealias: localStorage.getItem("tablealias"),
    msg: message,
  };

  try {
    const response = await authFetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(`Failed to send email: ${response.statusText}`);
    }

    return await response.json(); // Return the API response
  } catch (error) {
    console.error("Error in sendEmail API call:", error);
    throw error; // Re-throw the error for handling by the caller
  }
};

export const sendFbPagesDetails = (messengerData) => {
  const urlfb = `${API_URL_V2}/fbPagesDetails`;

  return new Promise((resolve, reject) => {
    authFetch(urlfb, {
      method: "POST",
      body: JSON.stringify(messengerData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Failed to fetch FB pages details: ${response.statusText}`);
        }
        return response.json(); // Return the response as a JSON object
      })
      .then((data) => {
        resolve(data); // Resolve with the response data
      })
      .catch((error) => {
        console.error("Error in sendFbPagesDetails:", error);
        reject(error); // Reject with the error
      });
  });
};


export const deployMessenger = async (messengerData) => {
  const deployUrl = `${API_URL_V2}/deployMessenger?fileid=${utilities.getCookie('APPYID')}`;

  return authFetch(deployUrl, {
    method: "POST",
    body: JSON.stringify(messengerData),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
      console.log(response)
      if (!response.ok) {
        return Promise.reject(new Error(`Failed to deploy messenger: ${response.statusText}`));
      }
      return response.json(); // Return the API response data
    })
    .catch((error) => {
      console.error("Error in deployMessenger:", error);
      return Promise.reject(error); // Re-throw the error to handle it in the calling function
    });
};




export const manageTelegramBot = async (body) => {
  const uninstallUrl = `${API_URL_V2}/manage_bots/uninstall-telegram`;

  try {
    const response = await authFetch(uninstallUrl, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to update bot status: ${response.statusText}`);
    }

    return await response.json(); // Return the response from the API
  } catch (error) {
    console.error("Error in manageTelegramBot:", error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};

export  const deployTelegramBot = async (body) => {
  const installUrl = `${API_URL_V2}/deployTelegram`;

  try {
    const response = await authFetch(installUrl, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to install Telegram bot: ${response.statusText}`);
    }

    return await response.json(); // Return the response data
  } catch (error) {
    console.error("Error in deployTelegramBot:", error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};


export const sendPaymentConversion = async (data) => {
  const urlfb = `${API_URL_V2}/payment_conversion`;
  try {
    const response = await authFetch(urlfb, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const responseData = await response.json(); // Parse the response as JSON
    return responseData; // Return the response data
  } catch (error) {
    console.error("Error in sendPaymentConversion:", error);
    throw error; // Rethrow the error to be handled by the calling function
  }
};

export const setupGoogleDrive = (body) => {
  return new Promise((resolve, reject) => {
    authFetch(API_URL_V2 + "/customDrive/setupGoogleDrive", {
      method: "POST",
      body: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((response) => {
        resolve(response); // Resolving with the response data
      })
      .catch((error) => {
        console.error("Error in setupGoogleDrive:", error);
        reject("Connection to Google sheet failed due to missing permission"); // Rejecting the promise with an error message
      });
  });
};


export const getEmailAuthorizationToken = (data) => {
  return new Promise((resolve, reject) => {
    const body = [`bt_id=${data.botId}&&tb=${data.tablealias}`];

    authFetch(`${API_URL_V2}/sendEmail/signin`, {
      method: "POST",
      body: body,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.token) {
          resolve(response.token); // Resolve with the token if present
        } else {
          reject("Authorization token not found");
        }
      })
      .catch((e) => {
        console.error("Error in getAuthorizationToken:", e);
        reject("Error fetching authorization token");
      });
  });
};

export const sendEmailDefault = (authorizationToken, data) => {
  return new Promise((resolve, reject) => {
    const dynamic = {};

    // Prepare dynamic fields
    if (data.visName) dynamic["Visitor Name"] = data.visName;
    if (data.visEmail) dynamic["Visitor Email"] = data.visEmail;
    if (data.visPhone) dynamic["Visitor Phone"] = data.visPhone;
    if (data.appointmentdate) dynamic["Appointment Date"] = data.appointmentdate;

    const ak1 = `bname=${data.botId}!!vname=${data.visName}!!vemail=${data.visEmail}!!vnumber=${data.visPhone}!!date=${data.appointmentdate}!!time=${data.appointmenttime}!!date=${data.visappointmentdate}!!time=${data.visappointmenttime}`;

    const body = JSON.stringify({
      token: authorizationToken,
      cid: data.tablealias,
      data: ak1,
      to: data.visEmail,
      bot_id: data.botId,
      which: "Appointment",
      stuff: JSON.stringify(dynamic),
    });

    authFetch(API_URL_V2 + "/sendEmail", {
      method: "POST",
      body: body,
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status) {
          resolve(response); // Resolve if the email is sent successfully
        } else {
          reject("Failed to send appointment email");
        }
      })
      .catch((e) => {
        console.error("Error in sendAppointmentEmail:", e);
        reject("Error sending appointment email");
      });
  });
};

export const fetchAllUrls = () => {
  return new Promise((resolve, reject) => {
    const url = `${API_URL}/customApi/getAllUrls?botId=${localStorage.botId}`;
    
    authFetch(url, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (!res.ok) {
          throw new Error('Failed to fetch URLs');
        }
        return res.json();
      })
      .then(data => resolve(data))
      .catch(error => reject(error));
  });
};


export const fetchUserData = (appyId) => {
  return new Promise((resolve, reject) => {
    const url = `${API_URL_V2}/getUserData?appyId=${appyId}`;
    
    authFetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (!res.ok) throw new Error('Failed to fetch user data');
        return res.json();
      })
      .then(data => resolve(data))
      .catch(err => reject(err));
  });
};


export const encryptBillingData = (payment) => {
  return new Promise((resolve, reject) => {
    const url = `${API_URL_V2}/commonBillingencryption`;

    authFetch(url, {
      method: 'POST',
      body: JSON.stringify(payment),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (!res.ok) throw new Error('Failed to encrypt billing data');
        return res.json();
      })
      .then(data => resolve(data))
      .catch(err => reject(err));
  });
};

export const apiRequest = (body) => {
  return new Promise((resolve, reject) => {
    fetch(API_URL_V2 + "/fetch", {
      method: "post",
      body: body,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((res) => res.json())
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};



/**
 * 
 * @param {*} requestData 
 * @returns 
 */
export const addFundsAPI = async (requestData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const encryptDecryptData = async (type, d) => {
        try {
          const apiUrl = API_URL_V2 + "/commonBillingencryption";
          const data = JSON.stringify({ action: type, string: d });

          const response = await axios.post(apiUrl, data, {
            headers: { "Content-Type": "application/json" }
          });

          console.log(response.data, "encryptDecryptData");
          return response.data;
        } catch (error) {
          console.error("Encryption/Decryption error:", error);
          throw error;
        }
      };
      console.log(requestData, "requestData")
      // Encrypt request data
      const encryptedData = await encryptDecryptData("encrypt", JSON.stringify(requestData));

      console.log(encryptedData, "encryptedData")

      // Axios request options
      const options = {
        method: "post",
        url: COMMON_BILLING_URL + "/api/",
        headers: { "Content-Type": "text/plain" },
        data: encryptedData.string, // Properly passing encrypted data
      };

      console.log("Sending request:", options);

      // API request
      const response = await axios(options);
      console.log(response, "Recived response")
      if (response.status >= 200 && response.status < 300) {
        // Decrypt response data
        const decryptedData = await encryptDecryptData("decrypt", response.data);
        resolve(decryptedData)
      } else {
        throw { status: "failed", message: `Request failed with status code ${response.status}` };
      }

    } catch (error) {
      console.error("Error in getWalletDetails:", error);
      reject(error)
    }
  })
}



