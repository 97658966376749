import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    showDeletePopup : false,
    showSubscriptionPopup : false,
    showUpgradePaymentPopup : false,
    showSwitchBotPopup : false,
    showDeleteNodePopup : false,
    deleteNodeFunction : null,
    changeTabPopup : false,
    changeTabPopupData : null,
    sendWidgetCodePopup : false,
    sideMenuMobileViewOpen : false,
    showLlmPopup : false , 
    showSideOptions : false,
    showFreePopup:false,
    showStatusPopup : false,
    statusPopupData : null,
    paymentBanner:false

}


const popupSlice = createSlice({
    name : "popup",
    initialState,
    reducers : {
        setStatusPopupData : (state , action) => {
            state.statusPopupData = action.payload
        },
        setShowStatusPopup : (state , action) => {
            state.showStatusPopup = action.payload
        },
        setDeletePopup : (state , action) => {
            state.showDeletePopup = action.payload
        },
        setsubscriptionPopup : (state , action) => {
            state.showSubscriptionPopup = action.payload
        },
        setShowUpgradePaymentPopup : (state , action) => {
            state.showUpgradePaymentPopup  = action.payload
        },
        setShowSwitchBotPopup : (state , action) => {
            state.showSwitchBotPopup  = action.payload
        },
        setShowDeleteNodePopup : (state , action) => {
            state.showDeleteNodePopup  = action.payload
        },
        setDeleteNodeFunction : (state , action) => {
            state.deleteNodeFunction  = action.payload
        },
        setChangeTabPopup : (state , action) => {
            state.changeTabPopup  = action.payload
        },
        setChangeTabPopupData : (state , action) => {
            state.changeTabPopupData  = action.payload
        },
        setSendWidgetCodePopup : (state , action) => {
            state.sendWidgetCodePopup  = action.payload
        },
        setSideMenuMobileViewOpen : (state , action) => {
            state.sideMenuMobileViewOpen  = action.payload
        },
        setShowLlmPopup : (state , action) => {
            state.showLlmPopup = action.payload
        },

        setShowSideOptions : (state , action) => {
            state.showSideOptions = action.payload
        },
        setFreePopup:(state,action)=>{
            state.showFreePopup=action.payload
        },
        setPaymentBanner:(state,action)=>{
            state.paymentBanner=action.payload
        }

        
    }

})


export const {setStatusPopupData,setShowStatusPopup,setShowLlmPopup,setChangeTabPopupData , setSideMenuMobileViewOpen ,  setSendWidgetCodePopup ,  setDeletePopup , setsubscriptionPopup , setShowUpgradePaymentPopup , setShowSwitchBotPopup , setShowDeleteNodePopup , setDeleteNodeFunction , setChangeTabPopup,setFreePopup,setShowSideOptions,setPaymentBanner} = popupSlice.actions

export default popupSlice.reducer